/* .content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
} */

/* .wrap-name {
    float: left;
    margin-left: 20px;
    padding: 6px;
    font-size: 2em;
} */

.select-status {
    width: 160px;
}

.search-event {
    width: 240px !important;
}

.export-btn {
    margin-top: 20px;
}

@media screen and (max-width: 750px) {
    /* .wrap-name {
        margin-left: 40px;
     } */

    .select-status {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-event {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .export-btn {
        width: 100%;
        margin: 0;
    }

    .topPanel {
        margin-top: 50px;
        /* padding: 6px; */
    }
}