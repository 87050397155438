@import url(https://fonts.googleapis.com/css?family=Kanit:400,400i&display=swap&subset=latin-ext,thai);
.wrap-name {
    float: left;
    margin-left: 20px;
    padding: 6px;
    font-size: 2em;
}

.select-status {
    width: 160px;
}

.search-event {
    width: 240px !important;
}

.filterTools {
    text-align: right;
    padding: 6px;
}

@media screen and (max-width: 750px) {
    .filterTools {
        margin-bottom: 12px;
        padding: 0;
    }

    .wrap-name {
        margin-left: 40px;
    }

    .select-status {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-event {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .ant-input-group>.search-event:not(:first-child) .ant-input {
        border-radius: 4px;
    }

    .export-event {
        width: 100%;
    }

    .removeTestModeBtn {
        width: 100%;
    }

   /*  .ant-table-wrapper {
        padding: 6px;
    } */

    .ant-table {
        font-size: 12px;
        line-height: 1;
    }
}
@font-face  {
    font-family:  'Rubik';
    src:  url(/static/media/Rubik-Regular.a4bece5c.ttf);
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Rubik';
    src:  url(/static/media/Rubik-Bold.366b50ff.ttf);
    font-style:  normal;
    font-weight:  bold;
}
@font-face  {
    font-family:  'Rubik';
    src:  url(/static/media/Rubik-Italic.03738d27.ttf);
    font-style:  italic;
    font-weight:  normal;
}

@font-face  {
    font-family:  'Roboto';
    src:  url(/static/media/Roboto-Regular.03523cf5.ttf);
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Roboto';
    src:  url(/static/media/Roboto-Bold.4f39c579.ttf);
    font-style:  normal;
    font-weight:  bold;
}
@font-face  {
    font-family:  'Roboto';
    src:  url(/static/media/Roboto-Italic.2a1b89d7.ttf);
    font-style:  italic;
    font-weight:  normal;
}

@font-face  {
    font-family:  'SukhumvitSet';
    src:  url(/static/media/SukhumvitSet-Light.43641489.eot);
    src:  url(/static/media/SukhumvitSet-Light.c8858513.ttf) format('truetype'),
            url(/static/media/SukhumvitSet-Light.31ad6b44.svg) format('svg'),
            url(/static/media/SukhumvitSet-Light.0bc690e7.woff) format('woff'),
            url(/static/media/SukhumvitSet-Light.2451780b.woff2) format('woff2');
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'SukhumvitSet';
    src:  url(/static/media/SukhumvitSet-Bold.972c5325.eot);
    src:  url(/static/media/SukhumvitSet-Bold.101b30e0.ttf) format('truetype'),
            url(/static/media/SukhumvitSet-Bold.5544f0cb.svg) format('svg'),
            url(/static/media/SukhumvitSet-Bold.f38e60fc.woff) format('woff'),
            url(/static/media/SukhumvitSet-Bold.8712ec73.woff2) format('woff2');
    font-style:  normal;
    font-weight:  bold;
}

.eventFgma .ant-table{
    font-family:'Rubik';
}
.eventFgma .ant-table-thead > tr > th
{
    font-size: 10px; 
    color:rgba(0, 0, 0, 0.34);
    height: "10px";
    padding: 5px 15px; 
    line-height: 12px;
    background-color:#FAFAFA; 
}
.eventFgma .ant-table-tbody > tr > th.align-left{
    text-align:left;
}
.eventFgma .ant-table-tbody footer{
    background:rgba(255, 255, 255, 1)!important;
}
.eventFgma .ant-table-tbody > tr > th.th-white, 
    .ant-table-tbody > tr > th.th-white, 
    .ant-table-tbody > tr > th .th-white{
    background-color:rgba(255, 255, 255, 1)!important;
    
}
.eventFgma .ant-table-tbody > tr > td{
    font-size:14px;
    font-family: 'Roboto', 'SukhumvitSet';
    color:rgba(0, 0, 0, 0.54);
}

.eventFgma .ant-table-tbody > tr > td .amount, .eventFgma .ant-table-tbody > tr > td.amount, .eventFgma .ant-table-tbody > tr.amount > td, .amount {
    min-width:100px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.default, .eventFgma .ant-table-tbody > tr > td.amount.default, .eventFgma .ant-table-tbody > tr.amount.default > td, .amount.default {
    width:20%!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.small, .eventFgma .ant-table-tbody > tr > td.amount.small, .eventFgma .ant-table-tbody > tr.amount.small > td, .amount.small {
    width:120px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.vsmall, .eventFgma .ant-table-tbody > tr > td.amount.vsmall, .eventFgma .ant-table-tbody > tr.amount.vsmall > td, .amount.vsmall {
    width:80px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.vvsmall, .eventFgma .ant-table-tbody > tr > td.amount.vvsmall, .eventFgma .ant-table-tbody > tr.amount.vvsmall > td, .amount.vvsmall {
    width:30px!important;
}
.eventFgma .ant-table-tbody > tr > td:first-child{
    text-align: left;
}
.eventFgma .ant-table-tbody > tr > td.align-left{
    text-align: left;
}
.eventFgma .ant-table-tbody > tr > td.fullx, .eventFgma .ant-table-tbody > tr > th.fullx, 
.subEventFgma .ant-table-tbody > tr > td.fullx, .subEventFgma .ant-table-thead > tr > th.fullx
{
    width: 100%;
}
.subEventFgma .ant-table-tbody > tr:last-child > td{
    border-bottom: none;
}
.subEventFgma > div{
    border-left: 1px solid rgb(219, 219, 219)!important;
}
.subEventFgma .ant-table-tbody > tr > td{
    padding-top:8px;
    padding-bottom:8px;
}

.eventFgma .ant-table div.ant-table-scroll > div.ant-table-body{
    overflow-x: auto!important;
}

.subEventFgma .ant-table-tbody > tr > td.string{
    padding-left: 20px;
}

.eventFgma .ant-table-expanded-row td{
    padding: 0px;
}
.eventFgma .ant-table-footer{
    padding: 0px;
    min-height: 35px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
}
.eventFgma .ant-tabs-bar{
    border-bottom: none;
}
.eventFgma .ant-tabs-tab {
    background:rgb(234,234,234,0)!important;
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important;     
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;    
    color: rgb(0,0,0,0.54);
    border:none!important;

}
.eventFgma .ant-tabs-tab:hover {
    font-weight: 400!important;
    color: rgb(0,0,0,1)!important;

}
.eventFgma .ant-tabs-tab-active{
    background:rgb(234,234,234,1)!important;
    border:none!important;
    color: rgb(0,0,0,1)!important;
    font-weight: 400;
}

.eventFgma .layout-header{
    font-family: 'Roboto', 'SukhumvitSet'!important;
    font-weight: 500;
    font-style: normal;
    line-height: 87px;
    height: 87px;
    padding-left: 20px;
    border-bottom: 1px solid #EAEAEA;
    color: rgb(0,0,0,1)!important;
}

.eventFgma .center{
    text-align: center!important;
}
.eventFgma .pin-circle{
    border: 3px solid #efefef;
    width:13px;    
    height:13px;
    background: none; 
    border-radius:20px;
    -webkit-border-radius:20px;
    -o-border-radius:20px;
    -moz-border-radius:20px;
    margin-top: 4px;
    margin-right: 10px;    
    display:block;

}
.eventFgma.mobile .pin-circle{
    margin-top: 0px;
}

.eventFgma .div-inline div{
    display:inline-block;
}
.eventFgma .div-inline.div-top div{
    vertical-align:top!important;
}

.eventFgma.limitHeight table {
    width:400px;
}

/*
.eventFgma.limitHeight table.ant-table-fixed thead, .eventFgma.limitHeight table.ant-table-fixed tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.eventFgma.limitHeight table.ant-table-fixed thead, .eventFgma.limitHeight .ant-table-footer{
    width: calc( 100% - 1.2em )
}

.eventFgma.limitHeight.mobile table.ant-table-fixed thead, .eventFgma.limitHeight.mobile .ant-table-footer{
    width: calc( 100% - 3px )!important;
}

.eventFgma.limitHeight.mobile table.ant-table-fixed td, .eventFgma.limitHeight.mobile table.ant-table-fixed td div.row-column-value{
    word-wrap: none!important;
}

.eventFgma.limitHeight table.ant-table-fixed tbody.ant-table-tbody{
    height: 350px!important;
    min-height: 350px!important;
    max-height: 350px!important;
    overflow-x: auto;
    display: block;
} 
*/
.eventFgma.limitHeight table.ant-table-fixed tbody.ant-table-tbody{
    display: none;
} 

.eventFgma .ant-switch{
    padding: 2px 8px;
    height: 28px;
    top:0px;
} 
.eventFgma .ant-switch::after, .eventFgma .ant-switch.antswitch.checkrd::after{
    width:22px!important;
    height:22px!important;
} 
.eventFgma .ant-switch-checked{
    background : #56C568!important;
} 

.fgma-mobile .ant-layout-sider.ant-layout-sider-below{
    position: absolute!important;
    z-index: 50000;
    box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
    -moz-box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
    -webkit-box-shadow: 0px 5px 8px rgb(0,0,0,.15); 
    -o-box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
}


.layout-events{
    border:0px; 
}
.layout-events .resize-trigger{
    border:1px solid red!important;   
}

.layout-events .ant-card-meta-description > div > div > div svg {
    width: 100%!important;
}
/*
.layout-events .ant-card-meta-description > div > div > div {
    border:0px solid red!important;   
    width: 100%!important;
}
.layout-events .ant-card-meta-description > div > div > div > div {
    border:0px solid red!important;   
    width: 100%!important;
}
*/

.layout-events .expand-trigger{
    border:0px solid green!important;    
}
.eventFgma-gray{
    font-family:'Roboto';
    font-size: 12px; 
    color:rgba(0, 0, 0, 0.20);   
}
.eventFgma .ant-table-row-expand-icon{
    border-color:darkgrey;
    color:darkgrey;
    background-color: none;
    /*
    border-radius: 10px;
    -o-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px; 
    padding-top:1px;     
    */      
}
.eventFgma .ant-table-row-expand-icon.ant-table-row-expanded{
    border-color:rgb(184, 184, 184);
    color: #FFF; 
    background-color:rgb(184, 184, 184);
}

.row-hide-expand .ant-table-row-expand-icon{
    /*display: none;*/
    color:rgb(238, 238, 238);
    border-color:rgb(238, 238, 238);  
    pointer-events: none;
}

.fgma-chart .chartHeader{
    font-family: 'SukhumvitSet'!important;
    font-size:20px!important;
    font-weight: 600;
    font-style: normal;
    color: #000000!important;
}

.eventFgma .fgma-chart{
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important; 
    background:#ffffff;
}

.eventFgma .fgma-card,.eventFgma .ant-card{
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important; 
    background:#ffffff;    
}







table.state-tab {
 font-size: 11em;
}
table.state-tab .thead{
    font-size: 10px;
    color:rgb(131, 129, 129);
}
.content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
}

.nav-hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 35px;
}

.btn {
    margin-right: 10px;
    margin-right: 11px;
}

.ant-form-item {
    margin-bottom: 7px;
}

@media screen and (max-width: 750px) {
    .form-content {
        padding: 24px !important;
        background-color: #ffffff !important;
    }

    .card-content {
        /* padding: 6px !important; */
        background-color: #f0f2f5 !important;
    }

    .content {
        padding: 6px;
        background-color: #ffffff;
    }
    
    .nav-hamburger {
        display: block;
    }

    .navbar {
        display: none;
    }

    .btn {
        width: 100%;
        margin: 0 !important;
    }

    .ant-form-item {
        margin-bottom: 14px;
    }
}
button.create,
button.add,
button.create:active,
button.add:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.edit,
button.edit:active {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
button.clone,
button.clone:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.delete,
button.delete:active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
button.back,
button.back:active {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}
button.action,
button.action:active {
  margin-bottom: 5px;
  display: block;
}

div.borderBottomItem {
  border-bottom: 1px solid #e8e8e8;
}
div.ant-steps-item.ant-steps-item-finish.ant-steps-item-custom div.stepTitle {
  color: #222;
}

div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
  display: initial;
}
div.ant-steps-horizontal.ant-steps-label-horizontal {
  display: inline-block;
}
div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  padding: 16px !important;
  display: flex;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
  margin: 0;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
  margin: auto;
}
div.stepTitle {
  font-size: 15px;
  line-height: 21px;
  white-space: pre-line;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 21px 0 6px;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
  height: 1px;
  width: 100%;
}
div.eventSteps div.ant-steps-item-custom .ant-steps-item-icon {
  width: 32px;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-icon.ant-steps-item-icon {
  width: auto;
  text-align: left;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-tail.ant-steps-item-tail {
  display: none;
}
div.eventSteps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: inline;
}
div.ant-steps-item-title {
  display: none;
}
@media only screen and (max-width: 769px) {
  div.stepTitle {
    font-size: 13px;
    line-height: 19px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    /* width: 52px; */
  }
}
@media only screen and (max-width: 480px) {
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: initial;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal {
    display: inline-block;
  }
  div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    padding: 8px !important;
    text-align: center;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
    margin: 0;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    width: 46px;
  }
  div.stepTitle {
    font-size: 10px;
    line-height: 15px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 21px 0 6px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
    height: 1px;
    width: 100%;
  }
}
div.ant-steps-item-custom.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon button,
div.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon button {
  color: #1890ff;
  border-color: #1890ff;
}
div.eventItem {
  padding: 20px;
}
form.eventForm {
  padding: 20px;
}
div.fixBottomPanel {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 5;
  background: white;
  border: 1px solid #dad4d4;
  padding: 3px;
  border-radius: 5px;
  left: 0;
  text-align: center;
}

.displayNone {
  display: none;
}

.metaPaymentStatistics thead.ant-table-thead {
  display: none;
}

.metaPaymentSummary thead.ant-table-thead {
  display: none;
}

div.sortableItems .item {
  border: 1px solid #bfbfbf;
  padding: 8px;
  cursor: move;
  max-width: 600px;
  border-radius: 5px;
  background: #f5f5f5;
  margin: 1px;
}
div.sortableItems {
  padding: 8px 0;
}

@media only screen and (max-width: 1001px) {
  div.paymentStatTableWrapper {
    overflow-x: scroll;
  }
  div.paymentStatTableWrapperInner {
    width: 1000px;
  }
}

button.inline {
  display: inline !important;
}
.ant-select-selection.ant-select-selection--single {
  min-width: 230px;
}

div.payments-mobile .ant-form-item-label {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item-control {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item {
  margin: 0 !important;
}

div.paymentSummary div.title {
  font-size: 20px;
  padding: 5px;
  margin-bottom: 16px;
}
div.paymentSummary .topPaymentDetail{
  margin-left: 16px;
}
div.paymentSummary td {
  word-break: break-word;
  border: 1px solid #e8e8e8;
}
div.paymentSummary .sum td {
  border-top: 2px solid black;
  border-bottom: 3px solid black;
}
div.paymentSummary .sumTop td {
  border-top: 2px solid black;
}
div.paymentSummary .topPaymentDetail p {
  margin-bottom: 5px;
}
div.paymentSummary {
  max-width: 795px;
}

div.paymentSummary .ant-table{
  font-size: 13px;
  /* font-family: 'Kanit', sans-serif; */
}


div.paymentSummary .ant-table-thead > tr > th,
div.paymentSummary .ant-table-tbody > tr > td {
  padding: 3px 8px;
}

div.paymentSummary div.marginTop {
  margin-top: 30px;
}

div.paymentSummary .ant-table-thead > tr > th {
  background: #e0dbdb;
}
div.paymentSummary .onlyHeader tbody {
  display: none;
}
div.print-payment-daily .line1 {
  padding-top: 20px;
}
div.print-payment-daily .line2 .line2-1 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
}
div.print-payment-daily .line2 .line2-2 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}
div.print-payment-daily .line3 table.table1,
div.print-payment-daily .line3 table.table2,
div.print-payment-daily .line3 table.table3,
div.print-payment-daily .line3 table.table4
{
  width: 100%;
}
div.print-payment-daily .line3 table.table2 td {
  text-align: center;
}

div.print-payment-daily .line3 table.table1 td {
 padding-top: 10px; 
 padding-left: 10px; 
 padding-right: 10px;
}
div.print-payment-daily .line3 table.table1 td,
div.print-payment-daily .line3 table.table2 .tr1 td
{
  border: 1px solid black;
  vertical-align: baseline;
}

div.print-payment-daily .line3 table.table2 .tr2 td
{
  border-left: 1px solid black;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table3 td
{
  border-left: 1px solid black;
  padding: 5px;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table3 .td3
{
  border-right: 1px solid black;
  /* padding: 5px; */
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table2
{
  border: 1px solid black;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table4 td
{
  border: 1px solid black;
}
div.print-payment-daily .line3 table.table3 .tr4
{
  border: 1px solid black;
  font-weight: bold;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table2 .tr2 .td3 {
  text-align: left;
  padding-left: 5px;
}
div.print-payment-daily .line3 table.table1 .tr1 .td1 {
  width: 60%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td1 {
  width: 7%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td2 {
  width: 12%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td3 {
  width: 41%;
}
div.print-payment-daily .line3 table.table1 .tr1 {
  height: 150px;
}
div.print-payment-daily .line3 table.table2 .tr1 {
  height: 50px;
}
div.print-payment-daily .line3 table.table2 {
  height: 700px;
}

div.print-payment-daily .line3 table.table3 .td1 {
  width: 50%;
}
div.print-payment-daily .line3 table.table3 .td2 {
  width: 35%;
  text-align: right;
}
div.print-payment-daily .line3 table.table3 .td3 {
  width: 15%;
  text-align: right;
}
div.print-payment-daily .line3 table.table4 .td1 {
  width: 50%;
  height: 221px;
}
div.print-payment-daily .line3 table.table4 .td2 {
  text-align: center;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby,
div.print-payment-daily .line3 table.table4 .td2 div.authby
{
  text-align: center;
  padding-top: 20px;
}
div.print-payment-daily .line3 table.table4 .td1 .context1
{
  font-size: 17px;
  font-weight: 500;
  padding-left: 10px;
  padding-top: 10px;
}
div.print-payment-daily .line3 table.table4 .td1 .context2
{
  font-size: 17px;
  font-weight: 500;
  padding-left: 10px;
}
div.print-payment-daily .line3 table.table4 .td2 .context1
{
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}
div.print-payment-daily .line3 table.table4 .td2 .context2
{
  font-size: 15px;
  font-weight: 500;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby2,
div.print-payment-daily .line3 table.table4 .td2 div.authby2
{
  text-align: center;
  padding-top: 20px;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby2 hr,
div.print-payment-daily .line3 table.table4 .td2 div.authby2 hr
{
  width: 150px;
}

div.print-payment-daily .line3 table.table4 .td1 div.authby2 span.slash1,
div.print-payment-daily .line3 table.table4 .td2 div.authby2 span.slash1
{
  padding-right: 50px;
}
div.print-payment-daily .line3 table.table4 td {
  vertical-align: baseline;
}
div.print-payment-daily-wrapper {
  position: absolute;
  z-index: 10;
  display: none;
}
.alignRight {
  text-align: right;
}
div.dailySlipListTable {
  background-color: white;
}
div.dailySlipListTable table {
  width: 100%;
}
div.dailySlipListTable th{
  padding: 5px;
  font-size: 12px;
  background-color: white;
}
div.dailySlipListTable .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
  text-align: center;
}
div.dailySlipListTable td{
  padding: 5px;
  font-size: 12px;
}
div.dailySlipListTable{
  width: 1000px;
}
div.print-payment-daily {
  /* position: absolute;
  z-index: 10; */
  background: white;
  width: 1000px;
  height: 1450px;
  /* border: solid 1px black; */
  /* margin: 30mm 45mm 30mm 45mm; */
}
div.print-payment-daily img.line1-logo {
  width: 150px;
  padding-left: 10px;
  vertical-align: baseline;
}
div.print-payment-daily .line1-b2 {
  display: inline-block;
  padding-left: 10px;
}
div.print-payment-daily .line1-b2 .head-l1 {
  font-weight: bold;
  font-size: 20px;
}
div.print-payment-daily tr.mainList td.td4,
div.print-payment-daily tr.mainList td.td5,
div.print-payment-daily tr.mainList td.td6,
div.print-payment-daily tr.mainList td.td7
{
  text-align: right;
  padding-right: 5px;
}

@media print {
  @page {
    size: a4;
  }
  div.print-payment-daily-wrapper {
    display: block;
  }
  .no-print {
    display: none !important;
  }
  div.paymentSummary .ant-table {
    font-size: 10px;
  }
  aside.ant-layout-sider.ant-layout-sider-dark {
    display: none;
  }
  header.header.ant-layout-header {
    display: none;
  }

  .topContent,
  .pro-sidebar,
  .ant-layout-sider-children,
  .printPdfBtn {
    display: none;
  }
  .desktop, 
  .topContent {
    border: unset !important;
  }
  .maincontent,
  .middleContent
   {
    padding: 0 !important;
    margin: 0 !important;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  thead.ant-table-thead {
    display: table-row-group;
  }
  .ant-layout-content {
    padding-top: 0 !important;
  }
  .filterTools {
    display: none;
  }

}

.disabled {
  pointer-events: none;
  color:#AAA;
  background:#F5F5F5;
}

.ticketGroupWrapper label.bigTitle span {
  font-size: 21px;
  padding: 0 3px;
}
div.ticketGroupWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
  max-width: 500px;
}
.ticketGroupWrapper .afterTicketGroup {
  padding: 0 30px
}
.ticketGroupWrapper .afterTicketGroup .ant-row.ant-form-item {
  margin: 0;
  margin-bottom: 15px;
}
div.ticketGroupPreviewWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
}
main.participantForm input,
main.participantForm textarea{
  max-width: 500px;
}
div.ebibPreviewLineHeight {
  line-height: 1.5 !important;
}
.colorGreen {
  color: green;
}
.colorOrange {
  color: orange;
}
.wrap-destop {
    /* margin: 0 auto; */
    padding: 20px;
}

/* @media screen and (max-width: 750px) {
    .content {
        background: #f0f2f5 !important;
    }
} */
.layout-content {
    background-color: #ffffff;
    padding: 20px;
}

.input-group {
    margin-bottom: 20px;
}

.select-type {
    width: 213px !important;
}

@media screen and (max-width: 750px) {
    .select-type {
        width: 100% !important;
    }
}
/* .content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
} */

/* .wrap-name {
    float: left;
    margin-left: 20px;
    padding: 6px;
    font-size: 2em;
} */

.select-status {
    width: 160px;
}

.search-event {
    width: 240px !important;
}

.export-btn {
    margin-top: 20px;
}

@media screen and (max-width: 750px) {
    /* .wrap-name {
        margin-left: 40px;
     } */

    .select-status {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-event {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .export-btn {
        width: 100%;
        margin: 0;
    }

    .topPanel {
        margin-top: 50px;
        /* padding: 6px; */
    }
}
