.layout-content {
    background-color: #ffffff;
    padding: 20px;
}

.input-group {
    margin-bottom: 20px;
}

.select-type {
    width: 213px !important;
}

@media screen and (max-width: 750px) {
    .select-type {
        width: 100% !important;
    }
}