@import url('https://fonts.googleapis.com/css?family=Kanit:400,400i&display=swap&subset=latin-ext,thai');
.content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
}

.nav-hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 35px;
}

.btn {
    margin-right: 10px;
    margin-right: 11px;
}

.ant-form-item {
    margin-bottom: 7px;
}

@media screen and (max-width: 750px) {
    .form-content {
        padding: 24px !important;
        background-color: #ffffff !important;
    }

    .card-content {
        /* padding: 6px !important; */
        background-color: #f0f2f5 !important;
    }

    .content {
        padding: 6px;
        background-color: #ffffff;
    }
    
    .nav-hamburger {
        display: block;
    }

    .navbar {
        display: none;
    }

    .btn {
        width: 100%;
        margin: 0 !important;
    }

    .ant-form-item {
        margin-bottom: 14px;
    }
}
button.create,
button.add,
button.create:active,
button.add:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.edit,
button.edit:active {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
button.clone,
button.clone:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.delete,
button.delete:active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
button.back,
button.back:active {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}
button.action,
button.action:active {
  margin-bottom: 5px;
  display: block;
}

div.borderBottomItem {
  border-bottom: 1px solid #e8e8e8;
}
div.ant-steps-item.ant-steps-item-finish.ant-steps-item-custom div.stepTitle {
  color: #222;
}

div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
  display: initial;
}
div.ant-steps-horizontal.ant-steps-label-horizontal {
  display: inline-block;
}
div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  padding: 16px !important;
  display: flex;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
  margin: 0;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
  margin: auto;
}
div.stepTitle {
  font-size: 15px;
  line-height: 21px;
  white-space: pre-line;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 21px 0 6px;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
  height: 1px;
  width: 100%;
}
div.eventSteps div.ant-steps-item-custom .ant-steps-item-icon {
  width: 32px;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-icon.ant-steps-item-icon {
  width: auto;
  text-align: left;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-tail.ant-steps-item-tail {
  display: none;
}
div.eventSteps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: inline;
}
div.ant-steps-item-title {
  display: none;
}
@media only screen and (max-width: 769px) {
  div.stepTitle {
    font-size: 13px;
    line-height: 19px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    /* width: 52px; */
  }
}
@media only screen and (max-width: 480px) {
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: initial;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal {
    display: inline-block;
  }
  div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    padding: 8px !important;
    text-align: center;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
    margin: 0;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    width: 46px;
  }
  div.stepTitle {
    font-size: 10px;
    line-height: 15px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 21px 0 6px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
    height: 1px;
    width: 100%;
  }
}
div.ant-steps-item-custom.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon button,
div.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon button {
  color: #1890ff;
  border-color: #1890ff;
}
div.eventItem {
  padding: 20px;
}
form.eventForm {
  padding: 20px;
}
div.fixBottomPanel {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 5;
  background: white;
  border: 1px solid #dad4d4;
  padding: 3px;
  border-radius: 5px;
  left: 0;
  text-align: center;
}

.displayNone {
  display: none;
}

.metaPaymentStatistics thead.ant-table-thead {
  display: none;
}

.metaPaymentSummary thead.ant-table-thead {
  display: none;
}

div.sortableItems .item {
  border: 1px solid #bfbfbf;
  padding: 8px;
  cursor: move;
  max-width: 600px;
  border-radius: 5px;
  background: #f5f5f5;
  margin: 1px;
}
div.sortableItems {
  padding: 8px 0;
}

@media only screen and (max-width: 1001px) {
  div.paymentStatTableWrapper {
    overflow-x: scroll;
  }
  div.paymentStatTableWrapperInner {
    width: 1000px;
  }
}

button.inline {
  display: inline !important;
}
.ant-select-selection.ant-select-selection--single {
  min-width: 230px;
}

div.payments-mobile .ant-form-item-label {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item-control {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item {
  margin: 0 !important;
}

div.paymentSummary div.title {
  font-size: 20px;
  padding: 5px;
  margin-bottom: 16px;
}
div.paymentSummary .topPaymentDetail{
  margin-left: 16px;
}
div.paymentSummary td {
  word-break: break-word;
  border: 1px solid #e8e8e8;
}
div.paymentSummary .sum td {
  border-top: 2px solid black;
  border-bottom: 3px solid black;
}
div.paymentSummary .sumTop td {
  border-top: 2px solid black;
}
div.paymentSummary .topPaymentDetail p {
  margin-bottom: 5px;
}
div.paymentSummary {
  max-width: 795px;
}

div.paymentSummary .ant-table{
  font-size: 13px;
  /* font-family: 'Kanit', sans-serif; */
}


div.paymentSummary .ant-table-thead > tr > th,
div.paymentSummary .ant-table-tbody > tr > td {
  padding: 3px 8px;
}

div.paymentSummary div.marginTop {
  margin-top: 30px;
}

div.paymentSummary .ant-table-thead > tr > th {
  background: #e0dbdb;
}
div.paymentSummary .onlyHeader tbody {
  display: none;
}
div.print-payment-daily .line1 {
  padding-top: 20px;
}
div.print-payment-daily .line2 .line2-1 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
}
div.print-payment-daily .line2 .line2-2 {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}
div.print-payment-daily .line3 table.table1,
div.print-payment-daily .line3 table.table2,
div.print-payment-daily .line3 table.table3,
div.print-payment-daily .line3 table.table4
{
  width: 100%;
}
div.print-payment-daily .line3 table.table2 td {
  text-align: center;
}

div.print-payment-daily .line3 table.table1 td {
 padding-top: 10px; 
 padding-left: 10px; 
 padding-right: 10px;
}
div.print-payment-daily .line3 table.table1 td,
div.print-payment-daily .line3 table.table2 .tr1 td
{
  border: 1px solid black;
  vertical-align: baseline;
}

div.print-payment-daily .line3 table.table2 .tr2 td
{
  border-left: 1px solid black;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table3 td
{
  border-left: 1px solid black;
  padding: 5px;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table3 .td3
{
  border-right: 1px solid black;
  /* padding: 5px; */
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table2
{
  border: 1px solid black;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table4 td
{
  border: 1px solid black;
}
div.print-payment-daily .line3 table.table3 .tr4
{
  border: 1px solid black;
  font-weight: bold;
  /* vertical-align: baseline; */
}
div.print-payment-daily .line3 table.table2 .tr2 .td3 {
  text-align: left;
  padding-left: 5px;
}
div.print-payment-daily .line3 table.table1 .tr1 .td1 {
  width: 60%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td1 {
  width: 7%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td2 {
  width: 12%;
}
div.print-payment-daily .line3 table.table2 .tr1 .td3 {
  width: 41%;
}
div.print-payment-daily .line3 table.table1 .tr1 {
  height: 150px;
}
div.print-payment-daily .line3 table.table2 .tr1 {
  height: 50px;
}
div.print-payment-daily .line3 table.table2 {
  height: 700px;
}

div.print-payment-daily .line3 table.table3 .td1 {
  width: 50%;
}
div.print-payment-daily .line3 table.table3 .td2 {
  width: 35%;
  text-align: right;
}
div.print-payment-daily .line3 table.table3 .td3 {
  width: 15%;
  text-align: right;
}
div.print-payment-daily .line3 table.table4 .td1 {
  width: 50%;
  height: 221px;
}
div.print-payment-daily .line3 table.table4 .td2 {
  text-align: center;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby,
div.print-payment-daily .line3 table.table4 .td2 div.authby
{
  text-align: center;
  padding-top: 20px;
}
div.print-payment-daily .line3 table.table4 .td1 .context1
{
  font-size: 17px;
  font-weight: 500;
  padding-left: 10px;
  padding-top: 10px;
}
div.print-payment-daily .line3 table.table4 .td1 .context2
{
  font-size: 17px;
  font-weight: 500;
  padding-left: 10px;
}
div.print-payment-daily .line3 table.table4 .td2 .context1
{
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}
div.print-payment-daily .line3 table.table4 .td2 .context2
{
  font-size: 15px;
  font-weight: 500;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby2,
div.print-payment-daily .line3 table.table4 .td2 div.authby2
{
  text-align: center;
  padding-top: 20px;
}
div.print-payment-daily .line3 table.table4 .td1 div.authby2 hr,
div.print-payment-daily .line3 table.table4 .td2 div.authby2 hr
{
  width: 150px;
}

div.print-payment-daily .line3 table.table4 .td1 div.authby2 span.slash1,
div.print-payment-daily .line3 table.table4 .td2 div.authby2 span.slash1
{
  padding-right: 50px;
}
div.print-payment-daily .line3 table.table4 td {
  vertical-align: baseline;
}
div.print-payment-daily-wrapper {
  position: absolute;
  z-index: 10;
  display: none;
}
.alignRight {
  text-align: right;
}
div.dailySlipListTable {
  background-color: white;
}
div.dailySlipListTable table {
  width: 100%;
}
div.dailySlipListTable th{
  padding: 5px;
  font-size: 12px;
  background-color: white;
}
div.dailySlipListTable .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
  text-align: center;
}
div.dailySlipListTable td{
  padding: 5px;
  font-size: 12px;
}
div.dailySlipListTable{
  width: 1000px;
}
div.print-payment-daily {
  /* position: absolute;
  z-index: 10; */
  background: white;
  width: 1000px;
  height: 1450px;
  /* border: solid 1px black; */
  /* margin: 30mm 45mm 30mm 45mm; */
}
div.print-payment-daily img.line1-logo {
  width: 150px;
  padding-left: 10px;
  vertical-align: baseline;
}
div.print-payment-daily .line1-b2 {
  display: inline-block;
  padding-left: 10px;
}
div.print-payment-daily .line1-b2 .head-l1 {
  font-weight: bold;
  font-size: 20px;
}
div.print-payment-daily tr.mainList td.td4,
div.print-payment-daily tr.mainList td.td5,
div.print-payment-daily tr.mainList td.td6,
div.print-payment-daily tr.mainList td.td7
{
  text-align: right;
  padding-right: 5px;
}

@media print {
  @page {
    size: a4;
  }
  div.print-payment-daily-wrapper {
    display: block;
  }
  .no-print {
    display: none !important;
  }
  div.paymentSummary .ant-table {
    font-size: 10px;
  }
  aside.ant-layout-sider.ant-layout-sider-dark {
    display: none;
  }
  header.header.ant-layout-header {
    display: none;
  }

  .topContent,
  .pro-sidebar,
  .ant-layout-sider-children,
  .printPdfBtn {
    display: none;
  }
  .desktop, 
  .topContent {
    border: unset !important;
  }
  .maincontent,
  .middleContent
   {
    padding: 0 !important;
    margin: 0 !important;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  thead.ant-table-thead {
    display: table-row-group;
  }
  .ant-layout-content {
    padding-top: 0 !important;
  }
  .filterTools {
    display: none;
  }

}

.disabled {
  pointer-events: none;
  color:#AAA;
  background:#F5F5F5;
}

.ticketGroupWrapper label.bigTitle span {
  font-size: 21px;
  padding: 0 3px;
}
div.ticketGroupWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
  max-width: 500px;
}
.ticketGroupWrapper .afterTicketGroup {
  padding: 0 30px
}
.ticketGroupWrapper .afterTicketGroup .ant-row.ant-form-item {
  margin: 0;
  margin-bottom: 15px;
}
div.ticketGroupPreviewWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
}
main.participantForm input,
main.participantForm textarea{
  max-width: 500px;
}
div.ebibPreviewLineHeight {
  line-height: 1.5 !important;
}
.colorGreen {
  color: green;
}
.colorOrange {
  color: orange;
}