@font-face  {
    font-family:  'Rubik';
    src:  url("../../../fonts/static/Rubik-Regular.ttf");
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Rubik';
    src:  url("../../../fonts/static/Rubik-Bold.ttf");
    font-style:  normal;
    font-weight:  bold;
}
@font-face  {
    font-family:  'Rubik';
    src:  url("../../../fonts/static/Rubik-Italic.ttf");
    font-style:  italic;
    font-weight:  normal;
}

@font-face  {
    font-family:  'Roboto';
    src:  url("../../../fonts/Roboto/Roboto-Regular.ttf");
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'Roboto';
    src:  url("../../../fonts/Roboto/Roboto-Bold.ttf");
    font-style:  normal;
    font-weight:  bold;
}
@font-face  {
    font-family:  'Roboto';
    src:  url("../../../fonts/Roboto/Roboto-Italic.ttf");
    font-style:  italic;
    font-weight:  normal;
}

@font-face  {
    font-family:  'SukhumvitSet';
    src:  url("../../../fonts/SukhumvitSet/SukhumvitSet-Light.eot");
    src:  url("../../../fonts/SukhumvitSet/SukhumvitSet-Light.ttf") format('truetype'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Light.svg") format('svg'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Light.woff") format('woff'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Light.woff2") format('woff2')
            ;
    font-style:  normal;
    font-weight:  normal;
}
@font-face  {
    font-family:  'SukhumvitSet';
    src:  url("../../../fonts/SukhumvitSet/SukhumvitSet-Bold.eot");
    src:  url("../../../fonts/SukhumvitSet/SukhumvitSet-Bold.ttf") format('truetype'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Bold.svg") format('svg'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Bold.woff") format('woff'),
            url("../../../fonts/SukhumvitSet/SukhumvitSet-Bold.woff2") format('woff2')
            ;
    font-style:  normal;
    font-weight:  bold;
}

.eventFgma .ant-table{
    font-family:'Rubik';
}
.eventFgma .ant-table-thead > tr > th
{
    font-size: 10px; 
    color:rgba(0, 0, 0, 0.34);
    height: "10px";
    padding: 5px 15px; 
    line-height: 12px;
    background-color:#FAFAFA; 
}
.eventFgma .ant-table-tbody > tr > th.align-left{
    text-align:left;
}
.eventFgma .ant-table-tbody footer{
    background:rgba(255, 255, 255, 1)!important;
}
.eventFgma .ant-table-tbody > tr > th.th-white, 
    .ant-table-tbody > tr > th.th-white, 
    .ant-table-tbody > tr > th .th-white{
    background-color:rgba(255, 255, 255, 1)!important;
    
}
.eventFgma .ant-table-tbody > tr > td{
    font-size:14px;
    font-family: 'Roboto', 'SukhumvitSet';
    color:rgba(0, 0, 0, 0.54);
}

.eventFgma .ant-table-tbody > tr > td .amount, .eventFgma .ant-table-tbody > tr > td.amount, .eventFgma .ant-table-tbody > tr.amount > td, .amount {
    min-width:100px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.default, .eventFgma .ant-table-tbody > tr > td.amount.default, .eventFgma .ant-table-tbody > tr.amount.default > td, .amount.default {
    width:20%!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.small, .eventFgma .ant-table-tbody > tr > td.amount.small, .eventFgma .ant-table-tbody > tr.amount.small > td, .amount.small {
    width:120px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.vsmall, .eventFgma .ant-table-tbody > tr > td.amount.vsmall, .eventFgma .ant-table-tbody > tr.amount.vsmall > td, .amount.vsmall {
    width:80px!important;
}
.eventFgma .ant-table-tbody > tr > td .amount.vvsmall, .eventFgma .ant-table-tbody > tr > td.amount.vvsmall, .eventFgma .ant-table-tbody > tr.amount.vvsmall > td, .amount.vvsmall {
    width:30px!important;
}
.eventFgma .ant-table-tbody > tr > td:first-child{
    text-align: left;
}
.eventFgma .ant-table-tbody > tr > td.align-left{
    text-align: left;
}
.eventFgma .ant-table-tbody > tr > td.fullx, .eventFgma .ant-table-tbody > tr > th.fullx, 
.subEventFgma .ant-table-tbody > tr > td.fullx, .subEventFgma .ant-table-thead > tr > th.fullx
{
    width: 100%;
}
.subEventFgma .ant-table-tbody > tr:last-child > td{
    border-bottom: none;
}
.subEventFgma > div{
    border-left: 1px solid rgb(219, 219, 219)!important;
}
.subEventFgma .ant-table-tbody > tr > td{
    padding-top:8px;
    padding-bottom:8px;
}

.eventFgma .ant-table div.ant-table-scroll > div.ant-table-body{
    overflow-x: auto!important;
}

.subEventFgma .ant-table-tbody > tr > td.string{
    padding-left: 20px;
}

.eventFgma .ant-table-expanded-row td{
    padding: 0px;
}
.eventFgma .ant-table-footer{
    padding: 0px;
    min-height: 35px;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
}
.eventFgma .ant-tabs-bar{
    border-bottom: none;
}
.eventFgma .ant-tabs-tab {
    background:rgb(234,234,234,0)!important;
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important;     
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;    
    color: rgb(0,0,0,0.54);
    border:none!important;

}
.eventFgma .ant-tabs-tab:hover {
    font-weight: 400!important;
    color: rgb(0,0,0,1)!important;

}
.eventFgma .ant-tabs-tab-active{
    background:rgb(234,234,234,1)!important;
    border:none!important;
    color: rgb(0,0,0,1)!important;
    font-weight: 400;
}

.eventFgma .layout-header{
    font-family: 'Roboto', 'SukhumvitSet'!important;
    font-weight: 500;
    font-style: normal;
    line-height: 87px;
    height: 87px;
    padding-left: 20px;
    border-bottom: 1px solid #EAEAEA;
    color: rgb(0,0,0,1)!important;
}

.eventFgma .center{
    text-align: center!important;
}
.eventFgma .pin-circle{
    border: 3px solid #efefef;
    width:13px;    
    height:13px;
    background: none; 
    border-radius:20px;
    -webkit-border-radius:20px;
    -o-border-radius:20px;
    -moz-border-radius:20px;
    margin-top: 4px;
    margin-right: 10px;    
    display:block;

}
.eventFgma.mobile .pin-circle{
    margin-top: 0px;
}

.eventFgma .div-inline div{
    display:inline-block;
}
.eventFgma .div-inline.div-top div{
    vertical-align:top!important;
}

.eventFgma.limitHeight table {
    width:400px;
}

/*
.eventFgma.limitHeight table.ant-table-fixed thead, .eventFgma.limitHeight table.ant-table-fixed tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}

.eventFgma.limitHeight table.ant-table-fixed thead, .eventFgma.limitHeight .ant-table-footer{
    width: calc( 100% - 1.2em )
}

.eventFgma.limitHeight.mobile table.ant-table-fixed thead, .eventFgma.limitHeight.mobile .ant-table-footer{
    width: calc( 100% - 3px )!important;
}

.eventFgma.limitHeight.mobile table.ant-table-fixed td, .eventFgma.limitHeight.mobile table.ant-table-fixed td div.row-column-value{
    word-wrap: none!important;
}

.eventFgma.limitHeight table.ant-table-fixed tbody.ant-table-tbody{
    height: 350px!important;
    min-height: 350px!important;
    max-height: 350px!important;
    overflow-x: auto;
    display: block;
} 
*/
.eventFgma.limitHeight table.ant-table-fixed tbody.ant-table-tbody{
    display: none;
} 

.eventFgma .ant-switch{
    padding: 2px 8px;
    height: 28px;
    top:0px;
} 
.eventFgma .ant-switch::after, .eventFgma .ant-switch.antswitch.checkrd::after{
    width:22px!important;
    height:22px!important;
} 
.eventFgma .ant-switch-checked{
    background : #56C568!important;
} 

.fgma-mobile .ant-layout-sider.ant-layout-sider-below{
    position: absolute!important;
    z-index: 50000;
    box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
    -moz-box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
    -webkit-box-shadow: 0px 5px 8px rgb(0,0,0,.15); 
    -o-box-shadow: 5px 0px 8px rgb(0,0,0,.15); 
}


.layout-events{
    border:0px; 
}
.layout-events .resize-trigger{
    border:1px solid red!important;   
}

.layout-events .ant-card-meta-description > div > div > div svg {
    width: 100%!important;
}
/*
.layout-events .ant-card-meta-description > div > div > div {
    border:0px solid red!important;   
    width: 100%!important;
}
.layout-events .ant-card-meta-description > div > div > div > div {
    border:0px solid red!important;   
    width: 100%!important;
}
*/

.layout-events .expand-trigger{
    border:0px solid green!important;    
}
.eventFgma-gray{
    font-family:'Roboto';
    font-size: 12px; 
    color:rgba(0, 0, 0, 0.20);   
}
.eventFgma .ant-table-row-expand-icon{
    border-color:darkgrey;
    color:darkgrey;
    background-color: none;
    /*
    border-radius: 10px;
    -o-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px; 
    padding-top:1px;     
    */      
}
.eventFgma .ant-table-row-expand-icon.ant-table-row-expanded{
    border-color:rgb(184, 184, 184);
    color: #FFF; 
    background-color:rgb(184, 184, 184);
}

.row-hide-expand .ant-table-row-expand-icon{
    /*display: none;*/
    color:rgb(238, 238, 238);
    border-color:rgb(238, 238, 238);  
    pointer-events: none;
}

.fgma-chart .chartHeader{
    font-family: 'SukhumvitSet'!important;
    font-size:20px!important;
    font-weight: 600;
    font-style: normal;
    color: #000000!important;
}

.eventFgma .fgma-chart{
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important; 
    background:#ffffff;
}

.eventFgma .fgma-card,.eventFgma .ant-card{
    border-radius: 8px!important;
    -moz-border-radius: 8px!important;        
    -webkit-border-radius: 8px!important;   
    -o-border-radius: 8px!important; 
    background:#ffffff;    
}






